import React from 'react';
import HomePage from './HomePage'; // Adjust the path as necessary

function App() {
    return (
        <div>
            <HomePage />
        </div>
    );
}

export default App;
